/* You can add global styles to this file, and also import other style files */
//@import "~font-awesome/css/font-awesome.css";
@import "~bootstrap/scss/bootstrap";

body {
  background-image: radial-gradient(circle at 57% 36%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 10%,transparent 10%, transparent 100%),radial-gradient(circle at 22% 61%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 68% 97%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 41%,transparent 41%, transparent 100%),radial-gradient(circle at 57% 89%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),radial-gradient(circle at 39% 80%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 22%,transparent 22%, transparent 100%),radial-gradient(circle at 88% 71%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),linear-gradient(345deg, rgb(255,255,255),rgb(255,255,255));
}


.welcome-bg {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  padding: 20px;
}


thead {
  font-weight: bold;
}

.scroll-container {
  height: calc(100vh - 54px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sub-scroller {
  height: calc(100vh - 112px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
}

@media screen {
  body {
    overflow: hidden;
  }
  .hide {
    display: none;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    animation: spin 2s linear infinite;
    position: absolute;
    left: calc(50vw - 5vw);
    top: calc(50vh - 5vw);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .hide-view {
    pointer-events: none;
    display: none;
    opacity: 0;
  }

  #topNavSpace {
    margin-bottom: 54px;
  }

  .student_image {
    max-width: 15vw;
    color: #ccc;
  }
}

@media print {
  body {
    background: white;
  }
  h4::first-letter {
    margin-top: 0mm;
  }

  #topNavSpace {
    display: none;
  }

  .hide-view {
    display: inherit;
  }

  .break-after {
    page-break-after: always;
  }

  .page {
    page-break-after: always !important;
    page-break-inside: avoid;
    padding: 0.5in;
  }

  .hide {
    display: none;
  }

  #content {
    display: block;
  }

  @page {
    margin: inherit;
    padding: inherit;
  }
}

a.btn.btn-primary {
  color: #fff;
}

th.overflow {
  overflow-x: visible;
  vertical-align: middle !important;
  transform: translateX(-50px);
  height: 220px;
}

.rotateVert {
  transform: rotate(315deg);
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.errorContainer {
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin: 1%;
  min-width: 250px;
  max-width: 300px;
  overflow-y: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
}
.tooltip {
  z-index: 1001 !important;
}

.google-signin {
  display: block;
  background-image: url("/assets/web/1x/btn_google_signin_dark_normal_web.png");
  border: 0;
  width: 191px;
  height: 46px;
  padding: 0;
}

.google-signin:hover {
  display: block;
  background-image: url("/assets/web/1x/btn_google_signin_dark_focus_web.png");
  border: 0;
  width: 191px;
  height: 46px;
}

.google-signin:focus {
  display: block;
  background-image: url("/assets/web/1x/btn_google_signin_dark_focus_web.png");
  border: 0;
  width: 191px;
  height: 46px;
}

.google-signin:active {
  display: block;
  background-image: url("/assets/web/1x/btn_google_signin_dark_pressed_web.png");
  border: 0;
  width: 191px;
  height: 46px;
}

.google-signin:visited {
  background-image: url("/assets/web/1x/btn_google_signin_dark_normal_web.png");
  border: 0;
  width: 191px;
  height: 46px;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
